<!-- 订单投诉处理查看弹窗 -->
<template>
  <el-dialog
      width="40%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'订单投诉处理':'订单投诉处理'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        label-width="80px">
      <el-row :gutter="15">
        <div class="xuanxianglist">
          <div class="xuanxianglistactive" v-for="(item,index) in list" :key="index"  :class="{xuanxianglistactive1:index==isShow}" @click="activeItem(item,index)">
            <div style="display: flex;align-items: center;justify-content: center;">
              <img :src="item.img" style="width: 16px;height: 16px;margin-right: 10px;">
              <span>{{item.name}}</span>
            </div>
          </div>
        </div>
      </el-row>

      <div style="margin-top: 15px;margin-bottom: 10px;">
        <span style="font-size: 14px;font-weight: 400;color: #7e7f8c;">客户投诉时间：</span>
        <span style="font-size: 14px;font-weight: 400;color: #202033;">2023-12-14 12:30:54</span>
      </div>
      <div style="margin-bottom: 10px;">
        <span style="font-size: 14px;font-weight: 400;color: #7e7f8c;">客户投诉原因：</span>
        <span style="font-size: 14px;font-weight: 400;color: #202033;">客户对救援师傅服务态度不好投诉</span>
      </div>
      <div style="margin-bottom: 10px;">
        <span style="font-size: 14px;font-weight: 400;color: #7e7f8c;">客户投诉内容：</span>
        <span style="font-size: 14px;font-weight: 400;color: #202033;">这是一条客户投诉内容这是一条客户投诉内容这是一条客户投诉内容</span>
      </div>
      <div style="margin-bottom: 10px;">
        <span style="font-size: 14px;font-weight: 400;color: #7e7f8c;">投诉处理说明：</span>
        <span style="font-size: 14px;font-weight: 400;color: #202033;">订单发生地偏远</span>
      </div>
      <div style="margin-bottom: 10px;">
        <el-row :gutter="15">
          <el-col :span="6">
            <div style="width: 100%;height: 140px;border-radius: 6px;">
              <img src="@/assets/images/home/order/shilitu.png" alt="" style="width: 100%;height: 100%;">
            </div>
          </el-col>
          <el-col :span="6">
            <div style="width: 100%;height: 140px;border-radius: 6px;">
              <img src="@/assets/images/home/order/shilitu.png" alt="" style="width: 100%;height: 100%;">
            </div>
          </el-col>
          <el-col :span="6">
            <div style="width: 100%;height: 140px;border-radius: 6px;">
              <img src="@/assets/images/home/order/shilitu.png" alt="" style="width: 100%;height: 100%;">
            </div>
          </el-col>
          <el-col :span="6">
            <div style="width: 100%;height: 140px;border-radius: 6px;">
              <img src="@/assets/images/home/order/shilitu.png" alt="" style="width: 100%;height: 100%;">
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="15" style="margin-top: 10px;">
          <el-col :span="6">
            <div style="width: 100%;height: 140px;border-radius: 6px;">
              <img src="@/assets/images/home/order/shilitu.png" alt="" style="width: 100%;height: 100%;">
            </div>
          </el-col>
          <el-col :span="6">
            <div style="width: 100%;height: 140px;border-radius: 6px;">
              <img src="@/assets/images/home/order/shilitu.png" alt="" style="width: 100%;height: 100%;">
            </div>
          </el-col>
          <el-col :span="6">
            <div style="width: 100%;height: 140px;border-radius: 6px;">
              <img src="@/assets/images/home/order/shilitu.png" alt="" style="width: 100%;height: 100%;">
            </div>
          </el-col>
          <el-col :span="6">
            <div style="width: 100%;height: 140px;border-radius: 6px;">
              <img src="@/assets/images/home/order/shilitu.png" alt="" style="width: 100%;height: 100%;">
            </div>
          </el-col>
        </el-row>
      </div>

      <div style="margin-top: 20px;">
        <div style="background: #eef5ff;border-radius: 6px;padding: 10px 20px 10px 20px;display: flex;align-items: center;margin-bottom: 10px;">
          <img src="@/assets/images/home/order/wenjian.png" alt="" style="width: 27px;height: 29px;">
          <span style="font-size: 14px;font-weight: 400;color: #4a7aff;margin-left: 20px;">针对订单DES256006的处理说明.docx</span>
        </div>
        <div style="background: #eef5ff;border-radius: 6px;padding: 10px 20px 10px 20px;display: flex;align-items: center;margin-bottom: 10px;">
          <img src="@/assets/images/home/order/wenjian.png" alt="" style="width: 27px;height: 29px;">
          <span style="font-size: 14px;font-weight: 400;color: #4a7aff;margin-left: 20px;">针对订单DES256006的处理说明.docx</span>
        </div>
      </div>


    </el-form>
    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({status: 1}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      //数据
      list:[
        {
          img: require('../../../../assets/images/home/genjindianhuaactive.png'),
          name:'拨打车主电话'
        },
        {
          img: require('../../../../assets/images/home/genjindianhua.png'),
          name:'拨打订单来源客户电话'
        },
        {
          img: require('../../../../assets/images/home/genjindianhua.png'),
          name:'拨打下游服务商电话'
        },
      ],
      isShow:0,

      radio:1,

      checkList:[
        {
          label:'订单发生地偏远'
        },
        {
          label:'当地没有司机'
        },
        {
          label:'夜间师傅少'
        },
        {
          label:'特殊天气运力紧张'
        },
        {
          label:'忘记跟单'
        },
        {
          label:'忘记完成回传跟进'
        },
      ],
      islist:0,


    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {

  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //选择类型
    activeItem(item,index) {
      console.log(item);
      console.log(index);
      this.isShow = index;
      if (item.name == '车主电话') {
        this.list[0].img = require('../../../../assets/images/home/genjindianhuaactive.png');
        this.list[1].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[2].img = require('../../../../assets/images/home/genjindianhua.png');
      } else if (item.name == '拨打订单来源客户电话') {
        this.list[0].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[1].img = require('../../../../assets/images/home/genjindianhuaactive.png');
        this.list[2].img = require('../../../../assets/images/home/genjindianhua.png');
      } else if (item.name == '拨打下游服务商电话') {
        this.list[0].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[1].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[2].img = require('../../../../assets/images/home/genjindianhuaactive.png');
      }
    },


  }
}
</script>

<style scoped lang="scss">
.xuanxianglist{

  .xuanxianglistactive{
    width: 29%;
    cursor: pointer;
    background: #e8e8e8;
    border: 1px solid #dadada;
    border-radius: 6px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #7b7b7b;
    text-align: center;
    margin-top: 0;
    margin-right: 10px;
    display: inline-block;
  }
  .xuanxianglistactive1{
    background: #FF9B05;
    color: #FFFFFF;
    border-color: #FF9B05;
  }

  //最后一个
  .xuanxianglistactive:last-child{
    margin-right: 0;
  }

}

.kuai{
  border: 1px solid #efeff2;
  background: #efeff2;
  border-radius: 6px;
  padding: 5px 15px 5px 15px;
  text-align: center;
  display: inline-block;
  margin: 10px;
  cursor: pointer;
}
.kuai:hover{
  background: #FFF4E3;
  border: 1px solid #ffd38e;
  color: #FF9B05;
}
.kuai1{
  background: #FFF4E3;
  border: 1px solid #ffd38e;
  color: #FF9B05;
}
</style>
